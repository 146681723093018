
export function user() {
  return JSON.parse(localStorage.getItem("user"))
}

export function isSuperAdmin(user) {
  return user?.role === "superAdmin"
}

export function isAdmin(user) {
  return user?.role === "admin" || user?.role === "superAdmin"
}

export function isNotAdmin(user) {
  return user?.role !== "admin" && user?.role !== "superAdmin"
}

export function isRoleSuperAdmin(role) {
  return role === "superAdmin"
}
