import axios from "axios";
import {baseUrl} from "../configurations/configurations";

export const API_URL = baseUrl()

export const axiosPublic = axios.create()

export const axiosAuthenticated = axios.create()

axiosAuthenticated.interceptors.request.use((config)=>{
  const token = localStorage.getItem("token")
  config.headers.Authorization = `Bearer ${token}`
  return config;
})
