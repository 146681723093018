import {API_URL, axiosAuthenticated, axiosPublic} from "./service";
import {isSuperAdmin, user} from "../utitlities/utilities";

export async function serviceLogin(username, password) {
  const response = await axiosPublic.post(API_URL + "/users/auth/login", {
    username: username,
    password: password,
  })
  const user = response.data
  user.token = response.headers.authorization
  localStorage.setItem("token", user.token)
  localStorage.setItem("user", JSON.stringify(user))
  return user
}

export async function serviceUsers() {
  const response = await axiosAuthenticated.get(API_URL + "/users")
  return response.data
}

export async function serviceUser() {
  const response = await axiosAuthenticated.get(API_URL + "/users/profile/me")
  return response.data
}

export async function serviceUsersRoles() {
  const response = await axiosAuthenticated.get(API_URL + "/users/roles/list")
  console.log(response)
  return response.data.roles
}

export async function serviceCreateUser(email, password, name, surname, role, imageFile) {
  const formData = new FormData();
  formData.append('username', email);
  formData.append('password', password);
  formData.append('name', name);
  formData.append('surname', surname);
  formData.append('role', role);

  if (imageFile != null) {
    formData.append("image", imageFile);
  }

  const response = await axiosAuthenticated.post(API_URL + "/users", formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

export async function serviceUpdateUser(userId, name, surname, role, imageFile) {
  const formData = new FormData();

  if (name != null) {
    formData.append('name', name);
  }

  if (surname != null) {
    formData.append('surname', surname);
  }

  if (role != null) {
    formData.append('role', role);
  }

  if (imageFile != null) {
    formData.append("image", imageFile);
  }

  const response = await axiosAuthenticated.patch(API_URL + "/users/" + userId, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

export async function serviceUpdateUserEvent(userId, eventId) {
  const response = await axiosAuthenticated.patch(API_URL + "/users/" + userId, {
    eventId: eventId
  })
  return response.data
}

export async function serviceClearUserEvent(userId) {
  return await axiosAuthenticated.delete(API_URL + "/users/" + userId + "/event")
}

export async function serviceDeleteUser(userId) {
  const response = await axiosAuthenticated.delete(API_URL + "/users/" + userId)
  return response.data
}

export async function serviceUpdateUserPassword(userId, password) {
  const response = await axiosAuthenticated.patch(API_URL + "/users/" + userId + "/password", {
    password: password
  })
  return response.data
}


