export const fkAppLogoNegative = [
  '608 156',
  `

<svg width="608px" height="167px" viewBox="0 0 334 67" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>fkgroup</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="fkgroup" transform="translate(0.000000, -0.000000)" fill="#FFFFFF" fill-rule="nonzero">
            <path d="M328.07,0.00356992381 C330.880782,0.0804735564 333.103586,2.4097385 333.049003,5.2210429 C332.994421,8.0323473 330.682879,10.273576 327.87123,10.2413177 C325.059581,10.2090594 322.800068,7.9153866 322.81,5.10356992 C322.800841,3.72014745 323.357407,2.39306568 324.350646,1.43003976 C325.343884,0.467013842 326.68752,-0.0483057658 328.07,0.00356992381 M328.07,9.13356992 C329.113216,9.12331524 330.108349,8.69338021 330.830845,7.94078086 C331.55334,7.18818151 331.942316,6.17633596 331.91,5.13356992 C331.91,3.00727364 330.186296,1.28356992 328.06,1.28356992 C325.933704,1.28356992 324.21,3.00727364 324.21,5.13356992 C324.174808,6.18055859 324.565571,7.19708766 325.293012,7.95091302 C326.020454,8.70473838 327.022422,9.13145428 328.07,9.13356992 M326.07,2.22356992 L328.23,2.22356992 C329.66,2.22356992 330.47,2.68356992 330.47,3.92356992 C330.502557,4.33316512 330.356098,4.73678234 330.068448,5.03018562 C329.780798,5.3235889 329.38016,5.47801035 328.97,5.45356992 L330.5,7.95356992 L329.3,7.95356992 L327.91,5.51356992 L327.13,5.51356992 L327.13,7.95356992 L326.03,7.95356992 L326.07,2.22356992 Z M327.13,4.62356992 L328.13,4.62356992 C328.78,4.62356992 329.25,4.52356992 329.25,3.82356992 C329.25,3.12356992 328.6,3.11356992 328.08,3.11356992 L327.08,3.11356992 L327.13,4.62356992 Z" id="Shape"></path>
            <polygon id="Path" points="112.99 0.913569924 143.62 0.913569924 143.62 8.97356992 122.57 8.97356992 122.57 19.0435699 140.82 19.0435699 140.82 26.4835699 122.57 26.4835699 122.57 44.4935699 112.99 44.4935699"></polygon>
            <polygon id="Path" points="148.38 0.913569924 157.96 0.913569924 157.96 18.9835699 174.99 0.913569924 186.95 0.913569924 169.92 18.1235699 188.59 44.4935699 176.57 44.4935699 163.45 24.9035699 157.96 30.4635699 157.96 44.4935699 148.38 44.4935699"></polygon>
            <path d="M213.78,25.4235699 C212.07,33.0935699 208.22,36.3235699 199.87,36.3235699 C194.74,36.3235699 189.13,34.1235699 189.13,28.0535699 L196.06,28.0535699 C195.96,30.6035699 198.35,31.6735699 200.6,31.6735699 C205,31.6735699 206.26,27.8135699 207.6,22.8935699 L207.51,22.7935699 C205.918771,25.0514275 203.311779,26.3736523 200.55,26.3235699 C194.55,26.3235699 190.83,22.2235699 190.83,16.0235699 C190.83,8.65356992 195.47,0.793569924 203.58,0.793569924 C207.04,0.793569924 209.88,2.00356992 211.29,5.33356992 L211.4,5.33356992 L212.17,1.46356992 L218.81,1.46356992 L213.78,25.4235699 Z M197.78,15.5635699 C197.78,18.5635699 199.24,21.1335699 202.47,21.1335699 C207.47,21.1335699 209.83,15.8035699 209.83,11.5135699 C209.83,8.33356992 208.23,6.04356992 204.83,6.04356992 C200.19,6.04356992 197.75,11.5135699 197.75,15.5635699" id="Shape"></path>
            <path d="M222.36,1.50356992 L228.96,1.50356992 L227.96,6.04356992 L228.06,6.13356992 C229.633886,2.78479772 233.052006,0.69616531 236.75,0.823569924 C237.469034,0.820726951 238.187407,0.867504726 238.9,0.963569924 L237.48,7.70356992 C236.520617,7.45873425 235.538979,7.31132104 234.55,7.26356992 C229.23,7.26356992 227.33,11.2635699 226.4,15.5635699 L224.05,26.7435699 L217.13,26.7435699 L222.36,1.50356992 Z" id="Path"></path>
            <path d="M251.66,0.823569924 C258.59,0.823569924 263.42,4.24356992 263.42,11.5135699 C263.42,20.3935699 257.91,27.4235699 248.73,27.4235699 C241.73,27.4235699 237.16,23.7635699 237.16,16.5435699 C237.16,7.98356992 242.67,0.823569924 251.66,0.823569924 M249.07,22.2035699 C254.25,22.2035699 256.49,15.8635699 256.49,11.6135699 C256.49,8.23356992 255.02,6.04356992 251.41,6.04356992 C246.34,6.04356992 244.09,12.2935699 244.09,16.5435699 C244.09,19.5435699 245.8,22.2035699 249.09,22.2035699" id="Shape"></path>
            <path d="M287.92,26.7435699 L281.28,26.7435699 L282.05,23.2235699 L281.96,23.2235699 C279.85111,25.9469356 276.573451,27.505958 273.13,27.4235699 C268.35,27.4235699 265.76,25.0335699 265.76,20.6435699 C265.892591,19.1804415 266.136633,17.7295633 266.49,16.3035699 L269.62,1.50356992 L276.55,1.50356992 L273.46,16.3235699 C273.225822,17.3321104 273.08192,18.3595014 273.03,19.3935699 C273.03,20.9035699 274.73,21.9735699 276.34,21.9735699 C280.54,21.9735699 282.69,18.4635699 283.43,14.9735699 L286.26,1.54356992 L293.19,1.54356992 L287.92,26.7435699 Z" id="Path"></path>
            <path d="M297.13,1.49356992 L303.83,1.49356992 L303.15,4.62356992 L303.24,4.72356992 C304.81,1.98356992 307.73,0.823569924 310.81,0.823569924 C316.86,0.823569924 320.38,4.72356992 320.38,10.8235699 C320.38,19.1235699 315.99,27.4735699 306.66,27.4735699 C303.73,27.4735699 300.66,26.2035699 299.58,23.4235699 L299.44,23.4235699 L296.83,35.6335699 L289.89,35.6335699 L297.13,1.49356992 Z M300.9,16.7335699 C300.9,19.9635699 302.51,22.2035699 305.9,22.2035699 C311.12,22.2035699 313.47,16.0935699 313.47,11.6535699 C313.47,8.65356992 311.8,6.04356992 308.53,6.04356992 C303.36,6.04356992 300.92,12.3835699 300.92,16.7335699" id="Shape"></path>
            <path d="M104.77,33.9535699 C104.77,35.0735699 81.48,35.9535699 52.77,35.9535699 C44.92,35.9535699 37.47,35.8835699 30.77,35.7635699 L30.77,38.6235699 C37.45,38.7435699 44.89,38.8035699 52.77,38.8035699 C81.49,38.8035699 104.77,37.9035699 104.77,36.8035699 L104.77,33.9535699" id="Path"></path>
            <polygon id="Path" points="30.79 32.9635699 30.79 35.7935699 39.07 34.8835699 39.06 32.0535699"></polygon>
            <path d="M20.13,30.8735699 C20.1338044,30.4491058 20.1605099,30.0251562 20.21,29.6035699 C8.35,29.8735699 0,30.1035699 0,30.9635699 C0,32.0635699 18.92,33.1235699 30.8,32.9635699 L39.06,32.0535699 C28.64,32.0435699 20.13,31.6535699 20.13,30.8735699" id="Path"></path>
            <path d="M20.13,30.6035699 C20.13,30.7835699 20.13,30.8735699 20.13,30.8735699 C20.1313693,30.9150338 20.1540586,30.9528493 20.19,30.9735699 L20.19,30.9735699 L20.19,30.9735699 L20.31,31.0335699 L20.31,31.0335699 C20.5114681,31.122352 20.723039,31.1861591 20.94,31.2235699 C21.1,31.2235699 21.27,31.2935699 21.48,31.3135699 C21.7655794,28.8820659 22.1862295,26.4683353 22.74,24.0835699 C23.1193717,21.2115101 23.9672958,18.4211304 25.25,15.8235699 C23.9258444,17.8596971 22.8387876,20.0405209 22.01,22.3235699 C21.0213404,24.7984958 20.3883814,27.4010344 20.13,30.0535699 L20.13,30.0535699 C20.13,30.2435699 20.13,30.4435699 20.13,30.5735699 L20.13,30.5735699" id="Path"></path>
            <path d="M25.13,31.7035699 C25.79,21.6535699 28,13.3635699 30.95,9.44356992 C29.5635685,10.2807653 28.4434393,11.4945275 27.72,12.9435699 C25.45,16.9435699 23.72,23.5735699 23.01,31.5435699 C23.64,31.6035699 24.34,31.6535699 25.13,31.7035699" id="Path"></path>
            <path d="M27.27,31.8235699 L29.52,31.9035699 C30.29,18.6535699 33.74,7.95356992 38.25,4.40356992 C36.7214942,4.82922147 35.37994,5.75528116 34.44,7.03356992 C33.9278733,7.66272311 33.4664279,8.33148457 33.06,9.03356992 C30.13,13.8535699 27.97,22.0935699 27.27,31.8235699" id="Path"></path>
            <path d="M37.94,6.88356992 C34.74,12.1535699 32.47,21.2935699 31.94,31.9735699 C32.74,31.9735699 33.57,31.9735699 34.42,31.9735699 C35.01,16.9735699 39,4.83356992 44.16,2.05356992 C43.0728503,2.09710554 42.0254397,2.47417335 41.16,3.13356992 C39.8411905,4.14345679 38.7392821,5.40861079 37.92,6.85356992" id="Path"></path>
            <path d="M43.4,5.65356992 C40.09,11.1035699 37.79,20.7735699 37.4,32.0335699 L39.04,32.0335699 L39.04,34.8635699 L40.3,34.8635699 C40.3,17.8035699 44.91,3.67356992 50.92,1.09356992 C50.4922599,0.962219225 50.0474517,0.894824032 49.6,0.893569924 C48.7668522,0.899983713 47.9498503,1.12405595 47.23,1.54356992 C45.6143429,2.55075109 44.2877596,3.96003052 43.38,5.63356992" id="Path"></path>
            <path d="M49.3,5.54356992 C45.7,11.4835699 43.3,22.4035699 43.3,34.9235699 L46.75,34.9235699 C46.83,16.8435699 51.49,2.14356992 57.35,1.15356992 C56.7826788,0.917464396 56.174489,0.795146899 55.56,0.793515097 C55.1992863,0.791746245 54.8397828,0.835424242 54.49,0.923569924 C52.63,1.37356992 50.88,2.99356992 49.34,5.52356992" id="Path"></path>
            <path d="M55.59,5.88356992 C52.3,11.4735699 50.13,22.1835699 50.13,34.4635699 C50.13,34.6335699 50.13,34.7935699 50.13,34.9535699 L53.87,34.9535699 C53.87,34.5635699 53.87,34.1835699 53.87,33.7935699 C53.87,17.1935699 58.18,3.65356992 63.6,2.86356992 C62.8387995,2.17340436 61.8569739,1.77642074 60.83,1.74356992 C60.6323341,1.74490616 60.4350718,1.7616233 60.24,1.79356992 C58.57,2.07356992 57,3.52356992 55.63,5.87356992" id="Path"></path>
            <path d="M62.18,8.41356992 C59.13,13.6735699 57.05,23.5735699 57,34.9535699 L61,34.9535699 L61,34.1035699 C61,18.7135699 65.12,6.23356992 70.21,6.23356992 C70.3977204,6.23488402 70.5850161,6.25160685 70.77,6.28356992 C69.9258207,5.17860929 68.6471759,4.49010823 67.26,4.39356992 L67.26,4.39356992 C65.42,4.39356992 63.7,5.86356992 62.26,8.39356992" id="Path"></path>
            <path d="M68.88,12.2235699 C66.35,16.7635699 64.61,25.1635699 64.44,34.8835699 C66.16,34.8835699 67.81,34.8835699 69.38,34.8235699 C69.38,34.4135699 69.38,34.0035699 69.38,33.5935699 C69.38,21.3835699 72.44,11.4735699 76.22,11.4735699 C76.5665587,11.4819222 76.9073997,11.563724 77.22,11.7135699 C76.5545409,10.3678035 75.4303673,9.30391675 74.05,8.71356992 C73.7746238,8.62964342 73.4878476,8.58915737 73.2,8.59356992 C71.63,8.59356992 70.2,9.89356992 68.88,12.1935699" id="Path"></path>
            <path d="M75.65,18.6035699 C74,21.8735699 72.92,27.4735699 72.92,33.8335699 C72.92,34.1435699 72.92,34.4435699 72.92,34.7435699 C80.63,34.5535699 85.56,34.2735699 85.58,33.9435699 L85.58,33.8335699 C85.58,25.0835699 83.17,17.7735699 80.33,15.9735699 C79.9347753,15.711721 79.4739284,15.5661903 79,15.5535699 C77.75,15.5535699 76.6,16.6735699 75.64,18.6035699" id="Path"></path>
            <path d="M20.13,30.8935699 C20.13,30.8935699 20.13,30.2635699 20.2,29.6135699 C17.48,29.6635699 14.93,29.7235699 12.63,29.7835699 C12.6249648,29.8133586 12.6249648,29.8437812 12.63,29.8735699 C12.63,30.4935699 16.23,31.0535699 22.04,31.4535699 C20.82,31.3235699 20.13,31.1035699 20.13,30.8935699" id="Path"></path>
            <path d="M53.03,66.7635699 C69.12,66.7635699 81.87,55.2635699 84.66,40.0335699 C83.66,39.9435699 82.66,39.9035699 81.66,39.9035699 C66.0165548,39.917802 52.6629228,51.2098799 50.05,66.6335699 C51.05,66.7235699 52.05,66.7635699 53.05,66.7635699" id="Path"></path>
        </g>
    </g>
</svg>
`,
]
