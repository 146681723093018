import {fkAppLogo} from "../assets/brand/fkapp-logo";
import {tenkaLogo} from "../assets/brand/tenka-logo";
import {fkAppLogoNegative} from "../assets/brand/fkapp-logo-negative";
import {tenkaLogoNegative} from "../assets/brand/tenka-logo-negative";

export const baseUrl = () => {
  if (process.env.REACT_APP_NAME === "fkgroup") {
    return "https://api.fkapp.quintessenza.it/api/v1"
  }
  if (process.env.REACT_APP_NAME === "tenka") {
    return "https://api.tenka.quintessenza.it/api/v1"
  }
  return "https://api.fkapp.quintessenza.it/api/v1"
}

export const baseAppName = () => {
  if (process.env.REACT_APP_NAME === "fkgroup") {
    return "FKApp"
  }
  if (process.env.REACT_APP_NAME === "tenka") {
    return "Tenka"
  }
  return "FKApp"
}

export const baseLogo = () => {
  if (process.env.REACT_APP_NAME === "fkgroup") {
    return fkAppLogo
  }
  if (process.env.REACT_APP_NAME === "tenka") {
    return tenkaLogo
  }
  return fkAppLogo
}

export const baseLogoNegative = () => {
  if (process.env.REACT_APP_NAME === "fkgroup") {
    return fkAppLogoNegative
  }
  if (process.env.REACT_APP_NAME === "tenka") {
    return tenkaLogoNegative
  }
  return fkAppLogoNegative
}
